import React from 'react';
import { graphql } from 'gatsby';
import Student from '../components/student';
import Layout from '../components/layout';

export const query = graphql`
  query($slug: String!) {
    student(slug: { eq: $slug }) {
      frontmatter {
        calendly_url
        github_url
        linkedin_url
        portfolio_url
        resume_url
        soapbox_id
        student_bio
        student_name
        headshot
        final_project_url
        pitch_url
        location
      }
    }
  }
`;

const StudentTemplate = ({ data }) => {
  return (
    <Layout>
      <Student frontmatter={data.student.frontmatter} />
    </Layout>
  );
};

export default StudentTemplate;
