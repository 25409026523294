/**@jsx jsx */
import { Fragment } from 'react';
import { jsx, Box, Flex, Image, Grid } from 'theme-ui';
import { navigate } from 'gatsby';
import { Linkedin, GitHub, Calendar } from 'react-feather';
import Button from '../common/button';
import SEO from './seo';
import Cities from '../components/cities'

const Student = ({ frontmatter }) => {
  const {
    calendly_url,
    github_url,
    linkedin_url,
    portfolio_url,
    resume_url,
    soapbox_id,
    student_bio,
    student_name,
    headshot,
    final_project_url,
    pitch_url,
    location,
  } = frontmatter;

  const cloudinaryUrl =
        //    'https://res.cloudinary.com/resilientcoders-org/image/upload';
        'https://res.cloudinary.com/dktv8cf8b/image/upload';
  const faceDetectionUrl = 'c_fill,g_face,h_400,w_255/dpr_2.0,f_auto';
  const faceDetectionResponsive = 'c_fit,h_300,w_255/dpr_2.0';
  const completeImageUrl = `${cloudinaryUrl}/${faceDetectionResponsive}${headshot}`;

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 25,
        margin: '0 auto',
        maxWidth: '1150px',
      }}
    >
      <SEO description={student_bio} title={student_name} />

      <h1>Meet the Students</h1>

      <Grid columns={[1, 2, 2]} gap={[2, 3, 1]} sx={{ marginBottom: 30 }}>
        <Flex
          sx={{
            width: ['100%'],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            sx={{
              objectFit: ['cover'],
              height: ['250px', '100%', '100%'],
              width: ['60%', '100%', '100%'],
              maxHeight: '600px',
            }}
            src={[completeImageUrl]}
          ></Image>
        </Flex>
        <Flex
          sx={{
            justifyContent: 'space-between',
            maxHeight: '600px',
            flexDirection: 'column',
            p: 25,
          }}
        >
          <Box sx={{ marginBottom: 20 }}>
          <h3>{student_name}</h3>
          <h3>{location}</h3>
            <Flex>
              <a href={`${linkedin_url}`}>
                <Linkedin
                  size={32}
                  sx={{
                    backgroundColor: 'primary',
                    color: 'white',
                    padding: '5px',
                    marginRight: '5px',
                  }}
                />
              </a>
              <a href={`${github_url}`}>
                <GitHub
                  size={32}
                  sx={{
                    backgroundColor: 'primary',
                    color: 'white',
                    padding: '5px',
                    marginRight: '5px',
                  }}
                />
              </a>
              <a href={`${calendly_url}`}>
                <Calendar
                  size={32}
                  sx={{
                    backgroundColor: 'primary',
                    color: 'white',
                    padding: '5px',
                    marginRight: '5px',
                  }}
                />
              </a>
            </Flex>
          </Box>

          <p
            sx={{
              height: '600px',
              overflow: 'scroll',
            }}
          >
            {student_bio}
          </p>          
        </Flex>
      </Grid>

      {soapbox_id && (
        <Fragment>
          <h2>Final Project</h2>
          <iframe
            sx={{
              height: [220, 349, 400],
              width: ['80%'],
            }}
          //src={`//fast.wistia.net/embed/iframe/${soapbox_id.split('/')[4]}`}
            src={`${soapbox_id}`}
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
          ></iframe>
        </Fragment>
      )}

    {pitch_url && (
     <Fragment>
       <h2>Pitch</h2>
      <Box sx={{ width: '90%', marginBottom: 20 }}>
        <iframe
      sx={{ height: 500, width: '100%' }}
      src={`${pitch_url}`}
      name="pitch_embed"
      allowFullScreen
      frameBorder="0"
      allowtransparency="true"
      className="pitch_embed"
          ></iframe>
            </Box>
        </Fragment>
    )}

      <h2>Portfolio</h2>
      <Box sx={{ width: '90%', marginBottom: 20 }}>
        <iframe
          sx={{ height: 500, width: '100%' }}
          src={`https://${portfolio_url}`}
        ></iframe>

        <a sx={{ color: 'primary' }} href={`https://${portfolio_url}`}>
          Go to Site
        </a>
      </Box>

      <h2>Want to meet more students?</h2>
      <p sx={{ p: 25 }}>
        Our students come from a variety of backgrounds and perspectives, but
        they all share drive and passion
      </p>

          <Cities />
    </Flex>
  );
};

export default Student;
